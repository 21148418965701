import {useState} from "react";
import gandalf_gif from "../assets/gandalf.gif"
import background_music from "../assets/epic-sax.mp3"
import "./styles.css"

const Gandalf = () => {

    const [isLoggedIn, setLoggedIn] = useState(false);

    const dancingGandalf = <>
        <img className='gandalf-image' src={gandalf_gif} alt="Gendalf"/>
        <audio src={background_music} loop="true" autoPlay="true" hidden="true"/>
    </>

    const loginButton = <div style={{textAlign: 'center'}}>
        <button className='button-5' style={{marginTop: '2%'}} onClick={() => setLoggedIn(!isLoggedIn)}>Login</button>
    </div>


    return (
        <div className='gandalf-container'>
            <div className={isLoggedIn ? 'blue height-50' : 'white height-50'}/>
            <div className={isLoggedIn ? 'yellow height-50' : 'black height-50'}>
                {!isLoggedIn && loginButton}
            </div>

            {isLoggedIn && dancingGandalf}
        </div>
    )

}

export default Gandalf